export default function (groupedArticles: Array<any> | Record<string, any>) {
    /* ORDER TEMPLATE NAVIGATION */
    const orderTags = [
        "llm",
        "data-pipeline",
        "machine-learning",
        "time-series"
    ];

    const orderMap: Record<string, number> = {}

    orderTags.forEach((tag, index) => {
        orderMap[tag] = index
    });

    if (Array.isArray(groupedArticles)) {
        // If groupedArticles is an array, sort it
        return groupedArticles.sort((a, b) => {
            const aIndex = orderMap[a.id]
            const bIndex = orderMap[b.id]
            return aIndex - bIndex
        })
    } else if (typeof groupedArticles === 'object' && groupedArticles !== null) {
        // groupedArticles is an object
        const sortedObject: Record<string, any> = {}

        Object.keys(groupedArticles)
            .sort((a, b) => {
                const aIndex = orderMap[a]
                const bIndex = orderMap[b]
                return (aIndex !== undefined ? aIndex : Infinity) - (bIndex !== undefined ? bIndex : Infinity)
            })
            .forEach(key => {
                sortedObject[key] = groupedArticles[key]
            });

        return sortedObject
    }

    // If groupedArticles is neither an array nor an object, return it unchanged
    return groupedArticles
}
